import * as $ from "jquery";
import { openProgressbar, closeProgressbar } from "./progressbar";
import { handleError } from "./debug";

/**
 * @typedef {object} Request
 * @property {string} url
 * @property {object} [data]
 * @property {boolean} [noProgressBar]
 * @property {JQuery<HTMLElement>} [btn]
 * @property {boolean} [useJson]
 */

/**
 * 
 * @param {Request} param0 
 * @returns {Promise<object>}
 */
function ajaxSend({ url, data, noProgressBar, btn, useJson }) {
    if (!useJson) {
        for (let param in data) {
            if (data[param] === null || data[param] === '') delete data[param];
        }
    }

    if (btn) {
        btn.addClass('disabled');
        btn.attr('disabled', 'disabled');
    }

    if (!noProgressBar) { openProgressbar(); }

    let body = null;
    let contentType = undefined;
    if (data instanceof FormData) {
        body = data;
    } else if (useJson) {
        body = JSON.stringify(data);
        contentType = "application/json; charset=utf-8";
    } else {
        body = $.param(data);
        contentType = "application/x-www-form-urlencoded; charset=utf-8";
    }

    /** @type {RequestInit} */
    let requestOptions = {
        method: 'POST',
        body,
        cache: "no-cache"
    }

    if (contentType) {
        requestOptions.headers = {
            "Content-Type": contentType,
            "tuajax": "true"
        }
    } 

    return fetch(url, requestOptions).then(response => {
        if (response.status == 429) {
            return Promise.reject({ result: false, msg: "Превышено максимальное количество запросов, попробуйте позже" });
        }

        if (response.ok) {
            return response.json()
        }
        
        throw new Error(`bad response from ${url}. Data is ${JSON.stringify(data)}`);
    }).then(data => {
        if (!noProgressBar) { closeProgressbar(); }
        if (btn) {
            btn.removeClass('disabled');
            btn.removeAttr('disabled');
        }

        return data;
    }).catch(error => {
        if (!noProgressBar) { closeProgressbar(); }
        if (error instanceof Error) handleError(error, true, false);

        return Promise.reject(error);
    });
}

/**
 * 
 * @param {Request} param0 
 * @returns {Promise<object>}
 */
function ajaxGet({ url, noProgressBar }) {
    if (!noProgressBar) { openProgressbar(); }

    return fetch(url, {
        method: 'GET',
        cache: "no-cache",
        headers: {
            "tuajax": "true"
        }
    }).then(response => {
        return response.json()
    }).then(data => {
        if (!noProgressBar) { closeProgressbar(); }
        return data;
    }).catch(error => {
        if (!noProgressBar) { closeProgressbar(); }
        if (error instanceof Error) handleError(error, true, false);

        return Promise.reject(error);
    });
}

/**
 * 
 * @param {string} code 
 * @param {object} data 
 * @returns 
 */
function request(code, data) {
    return ajaxSend({
        url: `/request/${code}`,
        data: { parameters: JSON.stringify(data) }
    })
}

export { ajaxSend, ajaxGet, request };
